import '../../css/flags.css';


(function($) {

    if((languageAttributes.spokenLanguageCode=='es') || (languageAttributes.spokenLanguageCode=='ar') || (languageAttributes.spokenLanguageCode=='en') ){
		window.withJWT = 'yes';
	}
        
    let  loadingForm = false;
    let  formLoaded = false;

    function defineDemo() {
        conversiondata.push("hasdemo", true);
        conversiondata.push("defaultaccount", 2);
        conversiondata.push("factor", 1);
        conversiondata.push("expdays ", 700);

    }

    function defineSA() {
        window.ct = 63;
        conversiondata.push("saprocess",1);
    }

    if($('html').attr('lang') === 'cs'){
        $('html').attr('lang','cz');
    } else if ($('html').attr('lang') === 'ko'){
        $('html').attr('lang','kr');
    } else if ($('html').attr('lang') === 'zh-hans'){
        $('html').attr('lang','cn');
    }

    function doScrolling() {

        if(!loadingForm) {
            loadingForm = true;
            if (!formLoaded) {
                const script = document.createElement('script');
                document.body.appendChild(script);
                script.async = true;
                script.defer = true;
                script.src = `/registration-bundle-ext/iforex/${languageAttributes.registrationBundle}/main.js`;


                script.onload = () => {
                    $('#form').lpForm({
                        validationSuccessCallback: function (formInstance) {
                            var data = formInstance.data;
                            formInstance.completeForm();

                        }, phonePrefixPaddingLeft: '70px'
                    });

                    if ($('#demo-form').length > 0) {
                        defineDemo();
                    }

                    if ($('#formSA').length > 0) {
                        defineSA();
                    }

                };
                loadingForm = false;
                formLoaded = true;
            }
        }

    }

    window.scrollingTasks.push(doScrolling);

    window.callDoScrolling = true;
})(window.jQuery);
